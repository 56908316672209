
$fontPrimary: 'Amazon Ember', Sans-serif;
.amazon-header {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: #232f3e;
    color: #fff;
    padding: 15px 35px;
    h2{
        font-size: 1.4rem;
    font-family: $fontPrimary;
    font-weight: 400;
    }
    .amazon-header-account{
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        padding-right: 70px;
        img.amazon-avatar {
            width: 20px;
            height: 20px;
            border: 1px solid #ececec40;
            background-color: #ffffff9e;
            margin-right: 12px;
        }
        h6 {
            font-size: 16px;
            margin-top: 3px;
        }
    }
  
}

