.data-table {
    th {
      &.default:after {
        font-family: "Material Icons";
        content: "\e5d7";
        position: absolute;
        margin-left: 3px;
        color: #232f3e;

      }
      &.asc:after {
        font-family: "Material Icons";
        content: "\e5db";
        position: absolute;
        margin-left: 3px;
        color: #006170;
      }
      &.desc:after {
        font-family: "Material Icons";
        content: "\e5d8";
        position: absolute;
        margin-left: 3px;
        color: #006170;
      }
      &.sortable {
        cursor: pointer;
      }
    }
  }

.candidate-data-table {
  th {
    &.default:after {
      font-family: "Material Icons";
      content: "\e5d7";
      position: absolute;
      margin-left: 3px;
      color: #007980;

    }
    &.asc:after {
      font-family: "Material Icons";
      content: "\e5db";
      position: absolute;
      margin-left: 3px;
      color: #007980;
    }
    &.desc:after {
      font-family: "Material Icons";
      content: "\e5d8";
      position: absolute;
      margin-left: 3px;
      color: #007980;
    }
    &.sortable {
      cursor: pointer;
    }
  }
}

 