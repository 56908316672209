@import './scss/fonts';

$primaryColor: #232f3e;
$accentColor: #006170;
$fontPrimary: 'Amazon Ember', Sans-serif;


:root {
  /* Mono */
  --mono-70: #13152c;
  --mono-50: #313436;
  --mono-30: #5b6063;
  --mono-10: #ececec;
  --mono-05: #f1f2f3;
  --white: #ffffff;
  /* Primary */
  --primary-70: #4331b4;
  --primary-50: #6d5dd3;
  --primary-30: #a59be4;
  --primary-10: #dad7f5;
  --primary-05: #edebfa;
  /* Warning */
  --warning-70: #e69c45;
  --warning-50: #ffa942;
  --warning-05: #fff8ef;
  /* Danger */
  --danger-70: #bd2c2c;
  --danger-50: #dd3434;
  --danger-05: #feeaea;

  --fc-event-bg-color: #4d838b;
  --fc-event-border-color: #4d838b;
  --fc-button-active-bg-color: #006170;
  --fc-button-active-border-color: #006170;
  --fc-button-border-color: #232f3e;
  --fc-button-bg-color: #232f3e;
}
.fc {
.fc-button-primary{
  &:focus {
    background-color: #006170;
    box-shadow: none; 
    transition: 0.2s easing;
    }
}
.fc-button {
  border-radius: 1.5em !important;
}
.fc-h-event:hover{
  background-color: #006170;
}
}
html{
  scroll-behavior: smooth;
}
::selection{
  background-color: #00595f;
  color: #ffffff;
}
body {
  margin: 0;
  font-family: $fontPrimary;
  font-size: 1rem;
  color: var(--mono-70);
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
input{
  caret-color:#00595f;
}
a{
  color:#00595f;
}

@mixin placeholder_styles {
  color: #8a9fa4;
  font-size: 1rem;
  font-style: italic;
  font-weight: 500;
}
::-webkit-input-placeholder {
  @include placeholder_styles();
}
:-moz-placeholder {
  @include placeholder_styles();
}
::-moz-placeholder {
  @include placeholder_styles();
}
:-ms-input-placeholder { 
  @include placeholder_styles();
}
.App{
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  .amz_breadcum_main{
    display: flex;
    justify-content: flex-start;
  }
  .amz_breadcum{
    list-style: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    margin-right: 20px;
    &:hover{
      text-decoration: underline;
    }
    &::after{
      content: '>';
    margin-left: 14px;
    text-decoration: none;
    }
    + .active{
      color: rgb(0, 121, 128);
    }
  }
.amz_content_new_view{
  max-width: 100%;
  height: 100%;
}
.headerVerticalRequisition{
  position:sticky;
  z-index:1;
  top:0px;
  min-height: 11%;
  background:#F1F4F7 !important;
}
.amz_content_view {
  max-width: 1400px;
  margin: 15px auto;
}
.form_page_actions .amz_primary_btn {
  margin-left: 15px;
}
  .amz_primary_btn {
    padding: 6px 24px;
    background-color: rgb(0, 121, 128);
    color: #ffffff;
    border-radius: 30px;
    border: 3px solid rgb(0, 121, 128);
    font-size: 1rem;
    cursor: pointer;
    min-width: 130px;
    outline: 0;
    transition: .02s;
    vertical-align: middle;
    user-select: none;
    text-align: center;
    &:hover{
      background-color: #00595f;
      border: 3px solid #00595f;
    }
}
.amz_secondary_btn {
  padding: 6px 24px;
  background-color: #ffffff;
  color: rgb(0, 121, 128);
  border-radius: 30px;
  border: 3px solid rgb(0, 121, 128);
  font-size: 1rem;
  cursor: pointer;
  min-width: 130px;
  outline: 0;
  transition: .02s;
  vertical-align: middle;
  user-select: none;
  text-align: center;
  &:hover{
    color: #ffffff;
    background-color: rgb(0, 121, 128);
  }
}
.form_group_list {
  margin: 15px 35px;
}
.form_group_item {
  padding-bottom: 28px;
   label {
    font-size: 1.2rem;
    font-weight: 600;
    cursor: text;
    span{
      font-size: 1.2rem;
    font-weight: 600;
    cursor: text;
    cursor: pointer;
    }
  }
  input[type="text" i] {
    padding: 7px 9px;
    // border: 0.1px solid #000000ab;
    // border: 2px solid #81999b;
    width:  auto;
    max-width: 200px;
    height: 20px;
    font-size: 1.2rem;
  }
  .amz_select{
    width:  auto;
    min-width: 200px;
    max-width: 300px;
  }
  input[type="checkbox" i] {
    height: 20px;
    width: 20px;
    border: 2px solid #81999b;
  } 
  input[type="date" i], input[type="datetime-local" i] , input[type="time" i]  {
    padding: 7px 5px;
    // border: 0.1px solid #000000ab;
    // border: 2px solid #81999b;
    height: 20px;
    font-size: 1.2rem;
  }
  .form_field_view {
    font-size: 1.2rem;
    font-weight: normal;
    color: #000;
    word-break: break-word;
    max-width: 95%;
  }
}

.form_group_item.form_textarea {
  display: flex;
  flex-direction: column;
  textarea {
    width: auto;
    min-width: 100%;
    margin-top: 6px;
    padding: 10px;
    font-size: 1.2rem;
    border: 2px solid #81999b;
  }
}
.mw_100{
  min-width: 100px !important;
}
.disabled{
  pointer-events: none;
  cursor: not-allowed;
  background-color: #f0f0f0;
}
span.MuiTab-wrapper {
  font-size: 1rem;
  font-weight: 600;
  color: #007486;
  text-transform: capitalize;
}
.primary_color{
  color: #007980 !important;
}
.MuiLink-underlineHover {
  cursor: pointer;
}
.MuiSwitch-track.css-1yjjitx-MuiSwitch-track {
  background-color: #007980 !important;
}
.form_page_title {
  font-size: 1.3rem;
  margin: 10px 0;
  text-transform: capitalize;
}
.m-tb-10{
  margin-top: 10px;
  margin-bottom: 10px;
}
.datatable_search_action {
  border: 2.5px solid #81999b;
  display: flex;
  margin-right: 15px;
}
.form_page_actions{
  display: flex;
  justify-content: flex-end;
}
.display_grid_actions{
  display: flex;
    flex-direction: row-reverse;
}
.data_grid_dropdown {
  color: rgb(0, 121, 128);
  outline: none;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  margin-right: 10px;
  cursor: pointer;
  text-transform: capitalize;
}
.form_field_checkbox {
  vertical-align: middle;
  display: flex;
  cursor: pointer;
 label {
    margin-left: 9px;
    cursor: pointer;
}
}
.data_grid_search{
  padding: 10px 10px 10px 0;
    width: 169px;
    font-size: 1rem;
    border: none;
    outline: none;
    color: rgb(0, 121, 128);
}
.d-flex{
  display: flex;
}
.text-center{
  justify-content: center;
}
.pr-0{
  padding-right: 0;
}
.p-0{
  padding: 0;
}
.m-0{
  margin: 0;
}
.mt-3{
  margin-top: 3px;
}
.mt-5{
  margin-top: 5px;
}
.mt-10{
  margin-top: 10px;
}
.w-100{
  width: 100%;
}
.mr-10{
  margin-right: 10px;
}
.mr_15{
  margin-right: 15px !important;
}
.mb-50{
  margin-bottom: 50px;
}
.mb-100{
  margin-bottom: 100px;
}
.mb-10{
  margin-bottom: 10px;
}
.mb-15{
  margin-bottom: 15px;
}
.form_group {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 10px 30px;
}

.form_sub_heading{
  font-size: 1.2rem;
    margin: 10px 0;
    color: #666;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-weight: 500;
    &::after {
      content: '';
      border-top: 1px solid #999;
      margin: 0 20px 0 0;
      flex: 1 0 20px;
  }
    &::after {
        content: '';
        border-top: 1px solid #999;
        margin: 0 20px 0 0;
        flex: 1 0 20px;
    }

    &::after {
      margin: 0 0 0 20px;
    } 
}
.amz_datatable{
  width: 100%;
  .MuiTableCell-stickyHeader {
    background-color: #eef5f6;
    font-size: 1rem;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
}
.MuiTableCell-root {
  padding: 12px 1rem;
  font-size: 1rem;
  font-family: $fontPrimary;
  font-weight: 500;
  border-bottom: transparent;
}
.MuiTableCell-stickyHeader {
  top: 0;
  left: 0;
  z-index: 2;
  position: sticky;
  background-color: #e9f1f2;
  padding: 10px 1rem;
  font-size: 1rem;
  font-weight: bold;
}
.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #bcd1d8;
  cursor: pointer;
}
} 
.amz_field_error{
  color: #ff0039;
  position: absolute;
}
.amz_data_table{
  max-height: calc(100vh - 275px);
}
.view_page_module{
  .form_group_list {
    margin-left: 10rem;
}
}
.font_bold{
  font-weight: bold !important;
}
.amz_menu_dropdown {
  text-transform: capitalize !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  color: #00595f !important;
}
.loading_indicator:before {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999999;
  opacity: 0.5;
}

.loading_indicator:after {
  content: "";
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 999999999;
  width: 90px;
  height: 90px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border-top: 12px solid rgba(155, 224, 240, 0.2);
  border-right: 12px solid rgba(155, 224, 240, 0.2);
  border-bottom: 12px solid rgba(155, 224, 240, 0.2);
  border-left: 12px solid #007980;
  width: 43px;
  height: 43px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.hide_show_btn{
  text-transform: capitalize !important;
  font-size: 1.1rem !important;
}
.d-flex.right {
  justify-content: flex-end;
}
.hyperlink{
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.reports_view{
  input {
    padding: 5px 10px;
  }
 label{
    padding-bottom: 3px !important;
  }
}

.form_group_item_report {
  display: flex;
  flex-direction: column;
  label {
    font-size: 1rem;
    margin-bottom: 3px;
    font-weight: bold;
  }
}
.welcome_screen{
   h6 {
    font-size: 1.3rem;
    text-transform: lowercase;
}
   img {
    filter: none !important;
    margin-top: 25px;
}
  h2 {
    font-size: 2rem;
}

}
.view_page_module .activities_grid tr th:nth-child(6), .view_page_module .activities_grid tr th:nth-child(7), .view_page_module .activities_grid tbody tr td:nth-child(6), .view_page_module .activities_grid tbody tr td:nth-child(7) {
  display: none;
}

.box {
  float: left;
  height: 12px;
  width: 12px;
  margin-right: 5px;
  border: 1px solid black;
  clear: both;
}

.orange {
  background-color: #ff6600;
}

.blue {
  background-color: #000099;
}

div[id^="keyDetailsInput"] {
  padding: 10px;
}

.css-1owk0rj {
  z-index: 99999 !important;
}