.amz-tabs {
    padding: 0 200px;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 0;
    box-shadow: 0px 14px 8px -14px #1111113d;
}
ul.amz-tabs.left {
    justify-content: flex-start;
    padding: 0;
}
.amz-tab-item {
    font-size: 1rem;
    font-weight: 600;
    color: #007486;
    padding: 13px 36px;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 5px solid transparent;
    &:hover, &:active  {
        border-bottom: 5px solid #007486;
    }
    .amz-tab-link{
        color: #007486;
        text-decoration: none;
    }
    .amz-tab-linkactive{
        color: #007486;
        text-decoration: none;
        
    }
}
.amz-tab-item.active{
    border-bottom: 5px solid #007486;
}
